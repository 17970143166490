import React, { useEffect, useLayoutEffect, useState } from "react";
import logo from "assets/FIMS.png";
import bg from "assets/bg.png";
import { Link, useLocation } from "react-router-dom";
import PagePadding from "./PagePadding";
import { Drawer, useLatestRef } from "@chakra-ui/react";
import {
  BASE_URL,
  HEADER_PATHNAMES,
  ONLINE_REPORTING_URL,
  swrOptions,
} from "constants/constants";
import CustomDrawer from "./Drawer";
import useSWR from "swr";
import { fetcher } from "constants/utils";
import useOrientation from "hooks/useOrientation";
import GlobalSearch from "./GlobalSearch";

function Header(props) {
  const {
    data: services,
    error,
    isValidating,
  } = useSWR(`${BASE_URL}/service`, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });
  const location = useLocation();
  const headerLinkMap = [
    {
      pathname: HEADER_PATHNAMES.HOME,
      title: "Home",
      submenu: false,
      isHidden: true,
    },
    {
      pathname: HEADER_PATHNAMES.OVERVIEW,
      title: "About Us",
      pathname: HEADER_PATHNAMES.ABOUT_US,
      submenu: [
        // {
        //   title: "Overview",
        //   pathname: HEADER_PATHNAMES.OVERVIEW,
        //   isHidden: false,
        // },
        {
          title: "Board Of Directors",
          pathname: HEADER_PATHNAMES.DIRECTORS,
          isHidden: false,
        },
        {
          title: "Vision, Mission & Values",
          pathname: HEADER_PATHNAMES.MISSION,
          isHidden: false,
        },
        {
          title: "Our Journey",
          pathname: HEADER_PATHNAMES.OUR_JOURNEY,
          isHidden: false,
        },
      ],
    },
    {
      pathname: HEADER_PATHNAMES.SPECIALITY,
      title: "Specialities",
      isSubmenuHiddenMobile: false,
      submenu: services?.map((s) => ({
        title: s.title,
        pathname: `${HEADER_PATHNAMES.SPECIALITY}/${s.url}`,
        isHidden: false,
      })),
    },
    {
      pathname: HEADER_PATHNAMES.OUR_DOCTORS,
      title: "Our Doctors",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.OPD_SCHEDULE,
      title: "OPD Schedule",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.EMPANELMENT,
      title: "Empanelment",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.GALLERY,
      title: "Gallery",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.ACADEMICS,
      title: "Academics",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.BLOGS,
      title: "Blogs",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.CAREERS,
      title: "Careers",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.ACCREDITATION,
      title: "Accreditation",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.CONTACT_US,
      title: "Contact Us",
      submenu: false,
    },
  ];
  let activeTab = "home";

  const [showMenu, setShowMenu] = useState(false);
  const [showLogo, setShowLogo] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        setShowLogo(window.pageYOffset > 120);
      });
    }
  }, []);

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  // useEffect(() => {
  //   window.addEventListener("scroll", listenToScroll);
  //   return () => window.removeEventListener("scroll", listenToScroll);
  // }, []);

  const listenToScroll = () => {
    let heightToShowFrom = 160;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToShowFrom) {
      setShowLogo(true);
    } else {
      setShowLogo(false);
    }
  };

  const MobileHeader = () => {
    const [activeSubmenu, setActiveSubmenu] = useState({
      data: headerLinkMap,
      isSubmenuActive: false,
    });
    return (
      <header className="h-full">
        <div className="flex flex-col h-full ">
          <div className="flex items-center justify-between">
            <div className="w-[70%]">
              <Link
                className="h-[70px] portrait:h-auto w-auto max-w-[300px]"
                to="/"
              >
                <img src={logo} alt="" className="h-full w-full object-cover" />
              </Link>
            </div>
          </div>
          <nav className="mt-4 flex-1 ">
            {activeSubmenu.isSubmenuActive ? (
              <div
                className="flex items-center "
                onClick={() => {
                  setActiveSubmenu({
                    data: headerLinkMap,
                    isSubmenuActive: false,
                  });
                }}
              >
                <div className="h-[20px] aspect-square">
                  <img
                    className="opacity-30 h-full"
                    src={process.env.PUBLIC_URL + "/assets/icons/back.svg"}
                    alt=""
                  />
                </div>
                <p className="ml-2 text-gray-500">Go Back</p>
              </div>
            ) : null}
            <div className="max-h-[80vh] overflow-y-auto">
              {activeSubmenu.data.map((header, i) => (
                <div className="flex justify-between items-center border-b py-2">
                  <Link
                    to={header.pathname}
                    key={i}
                    className="uppercase tracking-wider"
                  >
                    <h3>{header.title}</h3>
                  </Link>
                  {header.submenu && !header?.isSubmenuHiddenMobile ? (
                    <div
                      className="p-2"
                      onClick={() => {
                        setActiveSubmenu({
                          data: header.submenu,
                          isSubmenuActive: true,
                        });
                      }}
                    >
                      <img
                        className="opacity-30 h-[10px] aspect-square"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/icons/right-arrow.svg"
                        }
                        alt=""
                      />
                    </div>
                  ) : null}
                  {/* {header.submenu && !header?.isSubmenuHiddenMobile
                ? header.submenu.map((sub) => (
                    <div className="flex justify-between items-center border-b py-2">
                      <Link
                        to={sub.pathname}
                        key={sub.title}
                        className="uppercase tracking-wider "
                      >
                        <h3>{sub.title}</h3>
                      </Link>
                      <div className="h-[10px] aspect-square">
                        <img
                          className="opacity-30"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/right-arrow.svg"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  ))
                : null} */}
                </div>
              ))}
            </div>
          </nav>
          <div className="justify-self-end">
            <div className="bg-[#FFAC0A] rounded-lg px-2 py-1 button">
              <Link to={ONLINE_REPORTING_URL} className="text-white">
                Online Reporting
              </Link>
            </div>
            <span className="text-[#915b42] flex">
              <span className="font-bold">Emergency: </span>
              <a href="tel:+01302205000" className="ml-2">
                0130 - 2205000
              </a>
            </span>
          </div>
        </div>
        {activeSubmenu.active ? <div></div> : null}
      </header>
    );
  };

  return (
    <>
      <header
        id="header"
        className="w-full z-[1234] portrait:sticky portrait:shadow-xl top-0"
      >
        <div className="py-4 bg-white backdrop-blur-xl md:px-16 2xl:px-64 px-2 portrait:center relative z-[1234]">
          <div className="flex landscape:justify-between items-center portrait:w-full">
            <div
              className="hidden portrait:flex flex-col absolute px-2 z-[1234]"
              onClick={() => {
                setShowMenu(true);
              }}
            >
              {/* mobile menu */}
              <div className="h-[20px] aspect-square">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/menu.png"}
                  alt=""
                  className="h-full aspect-square"
                />
              </div>
            </div>

            <div className="flex-1 portrait:center flex portrait:flex-col landscape:justify-between items-center relative">
              <Link
                className="h-[70px] portrait:h-auto w-auto max-w-[300px] portrait:px-8 block"
                to="/"
              >
                <img
                  src={logo}
                  alt=""
                  className="h-full w-full object-contain"
                />
              </Link>
              {/* global search */}
              <div className="">
                <GlobalSearch />
              </div>

              <div className="flex mt-2 md:mt-0 flex-wrap justify-end items-center">
                <div className="center">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/Tel.png"}
                    alt=""
                    className="h-[30px] aspect-square"
                  />
                  <span className="md:text-center font-semibold mr-2 ml-4 text-[#915b42]">
                    Emergency:
                  </span>
                </div>
                <span className="md:pr-4 text-[#915b42]">
                  <a href="tel:+911302205000">+91 130 - 2205000</a>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* mobile menu  */}
        <CustomDrawer
          drawerOpen={showMenu}
          setDrawer={setShowMenu}
          component={<MobileHeader />}
          size={"xs"}
          showFooterButtons={false}
          placement="left"
        />
      </header>
      <nav className="sticky top-0 w-full z-[100]">
        <div
          id="nav"
          className="flex justify-between items-center backdrop-blur-xl 
        px-2 lg:page-padding bg-[#915b42]/50 shadow-lg absolute w-full portrait:hidden z-[100]"
        >
          {showLogo ? (
            <div className="h-full bg-white center flex-col max-w-[300px]">
              <Link
                className="h-[50px] portrait:h-auto w-auto  portrait:px-8 block p-2 rounded"
                to="/"
              >
                <img src={logo} alt="" className="h-full w-full object-cover" />
              </Link>
            </div>
          ) : null}

          <div className="py-4 flex flex-wrap uppercase header-links justify-center divide-x flex-1">
            {headerLinkMap.map((header, i) => {
              if (header.isHidden) return null;
              return (
                <div
                  className="group inline-block relative"
                  key={header.pathname}
                >
                  <Link
                    to={`/${header.pathname}`}
                    className={`cursor-pointer text-white underline-offset-4 last:border-0 px-1 xl:px-2 hover:underline 2xl:underline-offset-[12px] 2xl:decoration-4 block ${
                      location?.pathname === `/${header.title}` && "underline"
                    } decoration-[#FFAC0A]`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {header.title}
                  </Link>
                  {header?.submenu?.length ? (
                    <div className="absolute hidden text-gray-700 group-hover:block w-max pt-1 max-h-[400px] overflow-y-auto z-[100] shadow-lg">
                      {header?.submenu?.map((subItem) => (
                        <Link
                          className="block bg-white hover:bg-gray-100 py-2 px-4 hover:border-secondary hover:underline decoration-secondary underline-offset-4 decoration-2 hover:text-primary"
                          to={`/${subItem.pathname}`}
                          key={subItem.pathname}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          {subItem.title}
                        </Link>
                      ))}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
          {/* <div className="bg-[#FFAC0A]  rounded-lg px-4 py-2 button">
            <p className="text-white">Book an Appointment</p>
          </div> */}
        </div>
      </nav>
    </>
  );
}

export default Header;
