export const IS_DEBUG = false;

export const BASE_URL = IS_DEBUG
  ? "http://127.0.0.1:8000/api"
  : "https://fimssonipat.com/api";
export const ONLINE_REPORTING_URL =
  "http://210.212.98.5:8080/shivam1/onlinereporting/index.jsp";

export const BANNER_IMAGES = {
  empanelment: "assets/banners/empanelment.png",
  directors: "/assets/banners/directors.png",
  mission: "/assets/banners/vmv.png",
  overview: "/assets/banners/overview.png",
  ourJourney: "/assets/banners/ourJourney.png",
  opdSchedule: "assets/banners/opd-schedule.png",
  academics: "assets/banners/academics.png",
  careers: "assets/banners/careers2.png",
  accreditation: "assets/banners/accreditation.png",
  contactUs: "assets/banners/contactUs.png",
  ourDoctors: "assets/banners/ourDoctors.png",
  gallery: "assets/banners/gallery.png",
  blog: "assets/banners/blog.png",
  homepage: "assets/banners/homepage.webp",
};
export const HEADER_PATHNAMES = {
  HOME: "/",
  ABOUT_US: "about-us",
  DIRECTORS: "about-us/directors",
  MISSION: "about-us/vision-mission-values",
  OUR_JOURNEY: "about-us/our-journey",
  SPECIALITY: "speciality",
  OUR_DOCTORS: "our-doctors",
  OPD_SCHEDULE: "opd-schedule",
  EMPANELMENT: "empanelment",
  GALLERY: "gallery",
  ACADEMICS: "academics",
  BLOGS: "blogs",
  CAREERS: "careers",
  ACCREDITATION: "accreditation",
  CONTACT_US: "contact-us",
  SEARCH: "search",
};

export const APP_ID = `718913793494607`;
export const APP_SECRET = `04885b2be2d412826fae8f6b3b180aac`;
export const APP_TOKEN = `718913793494607|cArkQuOEgA_pL_I0f3SY9AL_otk`;
export const USER_TOKEN = `EAAKN2SlJDk8BO5AdYEWEZC2S4rE5KJglYWUku6UZBxnz0gZALVbh7nqeyERyEx0I78dLSoIA6p0Pb6PUzv6KNO7ELuFsv4qS7XLtXg1YTtTwzWOZBSDH7Jd7FRrajVlE0pP0OJ19jMHCe0GZBRXzo2072bTVrNrjup1fRwSG7diG7UNmzBT0L4wSFsAZDZD`;
export const CONTACT_US = `${BASE_URL}/contactusform/`;

export const swrOptions = {
  revalidateOnFocus: false,
  revalidateIfStale: false,
  revalidateOnReconnect: false,
};

export const INSTAGRAM_URL = "https://www.instagram.com/fimshospital/";
export const FACEBOOK_URL = "https://www.facebook.com/fimshospital/";
export const SOCIAL_MEDIA_URLS = {
  FACEBOOK: "https://www.facebook.com/fimshospital/",
  INSTAGRAM: "https://www.instagram.com/fimshospital/",
  YOUTUBE: "https://www.youtube.com/@fimshospital3532/featured",
};
export const sliderSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  initialSlide: 0,
  // afterChange: (current) => setActiveSlide(current),
  autoplay: process.env.NODE_ENV === "production" ? true : false,
  autoplaySpeed: 5000,
  // centerMode: true,
  responsive: [
    {
      breakpoint: 2569,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      },
    },
  ],
};

export const sliderSettings3 = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  initialSlide: 0,
  // afterChange: (current) => setActiveSlide(current),
  autoplay: process.env.NODE_ENV === "production" ? true : false,
  autoplaySpeed: 5000,
  // centerMode: true,
  responsive: [
    {
      breakpoint: 2569,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      },
    },
  ],
};
export const blogPageSliderSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  // afterChange: (current) => setActiveSlide(current),
  autoplay: process.env.NODE_ENV === "production" ? true : false,
  autoplaySpeed: 2500,
  arrows: true,
  dots: true,
  responsive: [
    {
      arrows: false,
    },
  ],
};

export const getBreadcrumbsData = (headerpath) => {
  const getKeyFromValue = (data, value) => {
    const values = Object.values(data);
    const findKeyIndex = values.indexOf(value);
    if (!findKeyIndex) return;
    return Object.keys(data)[findKeyIndex];
  };
  const response = [
    {
      link: HEADER_PATHNAMES.HOME,
      title: "Home",
    },
  ];
  const lvl3Headers = [
    HEADER_PATHNAMES.OUR_DOCTORS,
    HEADER_PATHNAMES.SPECIALITY,
  ];
  if (lvl3Headers.includes(headerpath)) return [];
  let res2 = headerpath.split("/")?.map((header) => ({
    title: header.split("-").join(" "),
    link: `/${header}`,
  }));
  response.push(...res2);
  // console.log(response);
  return response;
};
